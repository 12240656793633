import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister, resetRegister } from "../../features/RegisterSlice";
import { searchUser } from "../../services/Api/Module/User";

import Helper, {
  convertDateFormat,
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../services/helper";
import { PatternFormat } from "react-number-format";
import dayjs from "dayjs";

import PageStep from "../../components/step/PageStep";
import PageHeader from "../../components/header/PageHeader";
import ModalVerifyChangeMobileNo from "../../components/modal/verify/ModalVerifyChangeMobileNo";

import useTranslations from "../../i18n/useTranslations";

const VerifyFormPage = () => {
  const { t } = useTranslations();

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state

  const setDataRegister = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const resetDataRegister = () => {
    dispatch(resetRegister()); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("verifyForm");

  const PageRoute = {
    verifyOtpRequest: "/verify/otp/request",
    verifySurvey: "/verify/survey",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      gender: "",
      birthDate: "",
      mobileNo: "",
      userType: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
  });

  const [formIsValid, setFormIsValid] = useState(false);
  const [formIsFocus, setFormIsFocus] = useState("");

  const _mobileNo = stateRegister.dataUser.mobileNo;

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "mobileNo") {
        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        if (_value.length === 10) {
          if (formIsFocus === "mobileNo") {
            if (_mobileNo === _value) {
              clearErrors("mobileNo");
            } else {
              handleChkMobileExist(_value);
            }
          }
          _invalid = false;
        } else {
          _invalid = true;
        }
      }

      if (key === "birthDate") {
        const _value = val;

        const selectedDate = dayjs(_value);
        const currentDate = dayjs();
        const age = currentDate.diff(selectedDate, "year");

        if (_value !== "") {
          if (age >= 13) {
            clearErrors("birthDate");

            _invalid = false;
          } else {
            setError(
              "birthDate",
              {
                type: "manual",
                message: t.form.birthDate.validate.value.minAge,
              },
              {
                shouldFocus: true,
              }
            );

            _invalid = true;
          }
        }
      }

      if (key === "password" && formIsFocus === "password") {
        const _value = val;

        const _allMsgError = document.querySelectorAll(
          `#pRegisterForm .tValidate [data-rule]`
        );
        _allMsgError.forEach((item, index) => {
          item.setAttribute("data-validate", "0");
        });

        clearErrors("password");

        const _colorSuccess = "#079455";

        if (_value.length < 8) {
          setError(
            "password",
            {
              type: "manual",
              message: ``,
            },
            {
              shouldFocus: true,
            }
          );

          const _msgError = document.querySelector(
            `#pRegisterForm .tValidate [data-rule="8-char"]`
          );
          _msgError.setAttribute("data-validate", "1");
          _msgError.style.color = "";
        } else {
          const _msgError = document.querySelector(
            `#pRegisterForm .tValidate [data-rule="8-char"]`
          );
          _msgError.setAttribute("data-validate", "2");
          _msgError.style.color = `${_colorSuccess}`;
        }

        if (!/[a-z]/.test(_value)) {
          setError(
            "password",
            {
              type: "manual",
              message: ``,
            },
            {
              shouldFocus: true,
            }
          );

          const _msgError = document.querySelector(
            `#pRegisterForm .tValidate [data-rule="1-lowercase-letter"]`
          );
          _msgError.setAttribute("data-validate", "1");
          _msgError.style.color = "";
        } else {
          const _msgError = document.querySelector(
            `#pRegisterForm .tValidate [data-rule="1-lowercase-letter"]`
          );
          _msgError.setAttribute("data-validate", "2");
          _msgError.style.color = `${_colorSuccess}`;
        }

        if (!/[A-Z]/.test(_value)) {
          setError(
            "password",
            {
              type: "manual",
              message: ``,
            },
            {
              shouldFocus: true,
            }
          );

          const _msgError = document.querySelector(
            `#pRegisterForm .tValidate [data-rule="1-uppercase-letter"]`
          );
          _msgError.setAttribute("data-validate", "1");
          _msgError.style.color = "";
        } else {
          const _msgError = document.querySelector(
            `#pRegisterForm .tValidate [data-rule="1-uppercase-letter"]`
          );
          _msgError.setAttribute("data-validate", "2");
          _msgError.style.color = `${_colorSuccess}`;
        }

        if (!/^(?=.*[0-9])/i.test(_value)) {
          setError(
            "password",
            {
              type: "manual",
              message: ``,
            },
            {
              shouldFocus: true,
            }
          );

          const _msgError = document.querySelector(
            `#pRegisterForm .tValidate [data-rule="1-number"]`
          );
          _msgError.setAttribute("data-validate", "1");
          _msgError.style.color = "";
        } else {
          const _msgError = document.querySelector(
            `#pRegisterForm .tValidate [data-rule="1-number"]`
          );
          _msgError.setAttribute("data-validate", "2");
          _msgError.style.color = `${_colorSuccess}`;
        }

        if (!/^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/i.test(_value)) {
          setError(
            "password",
            {
              type: "manual",
              message: ``,
            },
            {
              shouldFocus: true,
            }
          );

          const _msgError = document.querySelector(
            `#pRegisterForm .tValidate [data-rule="1-special-char"]`
          );
          _msgError.setAttribute("data-validate", "1");
          _msgError.style.color = "";
        } else {
          const _msgError = document.querySelector(
            `#pRegisterForm .tValidate [data-rule="1-special-char"]`
          );
          _msgError.setAttribute("data-validate", "2");
          _msgError.style.color = `${_colorSuccess}`;
        }
      }

      if (key === "passwordConfirm") {
        let _value = val;

        if (_value === watch("password")) {
          _invalid = false;
        }
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  const handleValidateAge = (value) => {
    // console.log("handleValidateAge >>", value)

    const selectedDate = dayjs(value);
    const currentDate = dayjs();
    const age = currentDate.diff(selectedDate, "year");

    return age >= 13 || t.form.birthDate.validate.value.minAge;
  };

  useEffect(() => {
    // console.log("formIsValid >>", formIsValid);
    // console.log("formIsFocus >>", formIsFocus);
  }, [formIsValid, formIsFocus]);
  /* End Form */

  /* Handle click submit form */
  const onSubmit = (data) => {
    const _newMobileNo = convertMobileFormat(data.mobileNo);

    const newData = {
      ...data,
      mobileNo: _newMobileNo,
      id: stateRegister.dataUser.id,
    };
    // console.log("onSubmit >>", newData);

    setDataRegister(newData);

    if (_newMobileNo === _mobileNo) {
      goNext("verifySurvey");
    } else {
      handleToggleModel();
    }
  };
  /* End Handle click submit form */

  /* Check mobile nubmer exist from server  */
  const handleChkMobileExist = (mobileNo) => {
    // console.log(mobileNo);

    clearErrors("mobileNo");

    getSearchUser({
      mobileNo: mobileNo,
    }).then((_rsData) => {
      // console.log(_rsData);

      if (_rsData.status === true) {
        setFormIsValid(false);

        setError(
          "mobileNo",
          {
            type: "manual",
            message: t.form.mobileNo.validate.alreadyExist,
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        clearErrors("mobileNo");
      }
    });
  };

  const getSearchUser = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await searchUser({ params: payload });
      // console.log("getSearchUser data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR getSearchUser >> ", e);
    }

    return _return;
  };
  /* End Check mobile nubmer exist from server  */

  /* Handle show password */
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);

  const handleShowPass = (e) => {
    e.preventDefault();
    // console.log("click show password", e.currentTarget.dataset.id);

    if (e.currentTarget.dataset.id === "password") {
      setShowPass(showPass ? false : true);
    } else if (e.currentTarget.dataset.id === "passwordConfirm") {
      setShowPassConfirm(showPassConfirm ? false : true);
    }
  };
  /* End Handle show password */

  /* Modal verify mobile number */
  const [isModalShow, setIsModalShow] = useState(false);

  const handleToggleModel = (changeMobileNo) => {
    setIsModalShow(isModalShow ? false : true);

    if (changeMobileNo === true) {
      setTimeout(() => goNext("verifyOtpRequest"), 200);
    }
  };
  /* End Modal verify mobile number */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    if (Object.getOwnPropertyNames(stateRegister.dataUser).length === 0) {
      navigate("/");
    } else {
      let { dataUser } = stateRegister;

      if (Object.keys(dataUser).length !== 0) {
        setValue("email", dataUser.email);

        if (stateRegister.prevPage === "verifyOtpRequest") {
          setValue("firstname", dataUser.firstname);
          setValue("lastname", dataUser.lastname);
          setValue("gender", dataUser.gender);
          setValue("birthDate", convertDateFormat(dataUser.birthDate));
          setValue("mobileNo", convertMobileFormat(dataUser.mobileNo, "-"));
          setValue("userType", dataUser.userType);

          dataUser = { ...dataUser, password: "", passwordConfirm: "" };

          let _chkFormIsValid = true;
          Object.entries(dataUser).forEach(([key, val]) => {
            if (val === "" || val === false || val === null) {
              _chkFormIsValid = false;
            }
          });

          if (_chkFormIsValid === true) {
            setFormIsValid(true);
          }
        }
      }
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("showPass >>", showPass, showPassConfirm);

    document.querySelector("#password").type = showPass ? "text" : "password";
    document.querySelector("#passwordConfirm").type = showPassConfirm
      ? "text"
      : "password";
  }, [showPass, showPassConfirm]);

  // useEffect(() => {
  //   // console.log("newMobileNo >>", newMobileNo);
  // }, [newMobileNo]);

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageRegisterForm">
        <main>
          <PageHeader
            title={t.verifyFormPage.headerTitle}
            btnBack="false"
            btnBackImgClassName=""
          />

          <div className="wrapContent">
            <ModalVerifyChangeMobileNo
              open={isModalShow}
              onClose={(changeMobileNo) => handleToggleModel(changeMobileNo)}
            />

            <section id="pRegisterForm">
              <div className="bTitle">
                <p>{t.verifyFormPage.bodyTitle}</p>
                <p className="tSub">{t.verifyFormPage.bodySubtitle}</p>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => breakKeyDownEnter(e)}
              >
                <div className="bForm">
                  <div
                    className={`control-group validate ${
                      errors.firstname ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.firstname.label}
                    </div>
                    <Controller
                      name="firstname"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.firstname.validate.required,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder={t.form.firstname.placeholder}
                          onInput={Helper.FNFORM.handleCharOnly}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("firstname")}
                          maxLength="100"
                        />
                      )}
                    />
                    {errors.firstname && (
                      <label htmlFor="" className="tError">
                        {errors.firstname.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.lastname ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.lastname.label}
                    </div>
                    <Controller
                      name="lastname"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.lastname.validate.required,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder={t.form.lastname.placeholder}
                          onInput={Helper.FNFORM.handleCharOnly}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("lastname")}
                          maxLength="100"
                        />
                      )}
                    />
                    {errors.lastname && (
                      <label htmlFor="" className="tError">
                        {errors.lastname.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group bSexual ${
                      errors.gender ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">{t.form.gender.label}</div>
                    <Controller
                      name="gender"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.gender.validate.required,
                      }}
                      render={({ field }) => (
                        <div className="bSelectRadio">
                          <label className="control control--radio">
                            {t.form.gender.value.m}
                            <input
                              {...field}
                              type="radio"
                              value="m"
                              checked={field.value === "m"}
                              onFocus={() => setFormIsFocus("gender")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">
                            {t.form.gender.value.f}
                            <input
                              {...field}
                              type="radio"
                              value="f"
                              checked={field.value === "f"}
                              onFocus={() => setFormIsFocus("gender")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          <label className="control control--radio">
                            {t.form.gender.value.x}
                            <input
                              {...field}
                              type="radio"
                              value="x"
                              checked={field.value === "x"}
                              onFocus={() => setFormIsFocus("gender")}
                            />
                            <div className="control__indicator"></div>
                          </label>
                        </div>
                      )}
                    />
                    {errors.gender && (
                      <label htmlFor="" className="tError">
                        {errors.gender.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.birthDate ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.birthDate.label}
                    </div>
                    <Controller
                      name="birthDate"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.birthDate.validate.required,
                        validate: handleValidateAge,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="date"
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("birthDate")}
                        />
                      )}
                    />
                    {errors.birthDate && (
                      <label htmlFor="" className="tError">
                        {errors.birthDate.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.mobileNo ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.mobileNo.label}
                    </div>
                    <Controller
                      name="mobileNo"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.mobileNo.validate.required,
                        pattern: {
                          value: /^\d{3}-\d{3}-\d{4}$/,
                          message: t.form.mobileNo.validate.pattern,
                        },
                      }}
                      render={({ field: { name, value, onChange } }) => (
                        <div className="inputIcon">
                          <PatternFormat
                            name={name}
                            value={value}
                            displayType="input"
                            format="###-###-####"
                            placeholder={t.form.mobileNo.placeholder}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              handleCheckOnChange();
                            }}
                            onFocus={() => setFormIsFocus("mobileNo")}
                            disabled={true}
                          />
                        </div>
                      )}
                    />
                    {errors.mobileNo && (
                      <label htmlFor="" className="tError">
                        {errors.mobileNo.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.userType ? "error" : ""
                    }`}
                  >
                    <div className="tTitle">{t.form.userType.label}</div>
                    <Controller
                      name="userType"
                      control={control}
                      rules={{
                        required: t.form.userType.validate.required,
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <div className="select">
                          <select
                            {...field}
                            onFocus={() => setFormIsFocus("userType")}
                            disabled={true}
                          >
                            <option value="" disabled>
                              {t.form.userType.value.default}
                            </option>
                            <option value="0">
                              {t.form.userType.value["0"]}
                            </option>
                            <option value="1">
                              {t.form.userType.value["1"]}
                            </option>
                          </select>
                        </div>
                      )}
                    />
                    {errors.userType && (
                      <label htmlFor="" className="tError">
                        {errors.userType.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.email ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">{t.form.email.label}</div>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.email.validate.required,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: t.form.email.validate.pattern,
                        },
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="email"
                          placeholder={``}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          disabled={true}
                          autoComplete="username"
                          onFocus={() => setFormIsFocus("email")}
                        />
                      )}
                    />
                    {errors.email && (
                      <label htmlFor="" className="tError">
                        {errors.email.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate bPassword ${
                      errors.password ? "error" : ""
                    }`}
                  >
                    <p className="tTitle">{t.form.password.label}</p>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.password.validate.required,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <div className="controlPass">
                          <input
                            {...field}
                            type="password"
                            placeholder={t.form.password.placeholder}
                            onChange={({ target: { value } }) => {
                              onChange(
                                value.replace(
                                  /[^a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g,
                                  ""
                                )
                              );
                              handleCheckOnChange();
                            }}
                            id="password"
                            autoComplete="new-password"
                            onFocus={() => setFormIsFocus("password")}
                          />
                          <a
                            className="btnshowPass"
                            href="#"
                            data-id="password"
                            onClick={handleShowPass}
                          >
                            แสดง
                          </a>
                        </div>
                      )}
                    />

                    {errors.password && (
                      <label htmlFor="" className="tError">
                        {errors.password.message}
                      </label>
                    )}
                    <p className="tValidate" style={{ display: "block" }}>
                      <span data-validate="0" data-rule="8-char">
                        {t.form.password.remark["0"]}
                      </span>
                      ,{" "}
                      <span data-validate="0" data-rule="1-lowercase-letter">
                        {t.form.password.remark["1"]}
                      </span>
                      ,{" "}
                      <span data-validate="0" data-rule="1-uppercase-letter">
                        {t.form.password.remark["2"]}
                      </span>
                      ,{" "}
                      <span data-validate="0" data-rule="1-number">
                        {t.form.password.remark["3"]}
                      </span>{" "}
                      ,{" "}
                      <span data-validate="0" data-rule="1-special-char">
                        {t.form.password.remark["4"]}
                      </span>
                    </p>
                  </div>
                  <div
                    className={`control-group validate bPassword ${
                      errors.passwordConfirm ? "error" : ""
                    }`}
                  >
                    <div className="tTitle">{t.form.passwordConfirm.label}</div>
                    <Controller
                      name="passwordConfirm"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.passwordConfirm.validate.required,
                        validate: (value) =>
                          value === watch("password") ||
                          t.form.passwordConfirm.validate.match,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <div className="controlPass">
                          <input
                            {...field}
                            type="password"
                            placeholder={t.form.passwordConfirm.placeholder}
                            onChange={({ target: { value } }) => {
                              onChange(
                                value.replace(
                                  /[^a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/g,
                                  ""
                                )
                              );
                              handleCheckOnChange();
                            }}
                            id="passwordConfirm"
                            autoComplete="new-password"
                            onFocus={() => setFormIsFocus("passwordConfirm")}
                          />
                          <a
                            className="btnshowPass"
                            href="#"
                            data-id="passwordConfirm"
                            onClick={handleShowPass}
                          >
                            แสดง
                          </a>
                        </div>
                      )}
                    />
                    {errors.passwordConfirm && (
                      <label htmlFor="" className="tError">
                        {errors.passwordConfirm.message}
                      </label>
                    )}
                  </div>
                  <PageStep
                    title={t.verifyFormPage.pageStepTitle}
                    stepClassName="mt-56"
                    stepTotal="2"
                    stepActive="1"
                  />
                  <button
                    className="btn primary"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    {t.verifyFormPage.btnNext}
                  </button>
                </div>
              </form>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default VerifyFormPage;
