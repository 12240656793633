import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../../features/ProfileSlice";
import {
  province,
  createAddress,
  updateAddress,
} from "../../../../services/Api/Module/UserAddress";

import Helper, {
  convertDateFormat,
  convertMobileFormat,
  breakKeyDownEnter,
} from "../../../../services/helper";
import { PatternFormat } from "react-number-format";

import PageHeader from "../../../../components/header/PageHeader";
import ModalCreateSuccess from "../../../../components/modal/address/ModalCreateSuccess";
import ModalEditSuccess from "../../../../components/modal/address/ModalEditSuccess";

import useTranslations from "../../../../i18n/useTranslations";

const AddressTaxBillingFormPage = ({ pageView = "create" }) => {
  const { t, lang } = useTranslations();

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);
  const stateAddress = useSelector((state) => state.address);

  const setDataAddress = (newData) => {
    const _address = {
      ...stateProfile.dataAddress,
      taxBilling: newData,
    };

    const tmpObj = {
      dataAddress: _address,
    };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      id: "",
      addressType: 2,
      fullname: "",
      mobileNo: "",
      taxNo: "",
      branchName: "",
      branchCode: "",
      addressDetail: "",
      provinceId: "",
      amphureId: "",
      tombonId: "",
      postcode: "",
      isDefault: false,
    },
  });

  const [firstLoad, setFirstLoad] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formIsFocus, setFormIsFocus] = useState("");

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      if (key === "mobileNo") {
        let _value = val;
        _value = _value.replaceAll("-", "");
        _value = _value.trim();

        if (_value.length === 10) {
          _invalid = false;
        } else {
          _invalid = true;
        }
      }

      const _skipField = ["id", "branchCode", "isDefault"];
      if (_skipField.includes(key)) {
        _invalid = false;
      }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log("formIsValid >>", formIsValid);
    // console.log("formIsFocus >>", formIsFocus);
  }, [formIsValid, formIsFocus]);
  /* End Form */

  /* Handle click submit form */
  const postCreateAddress = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await createAddress({ data: payload });
      // console.log("data.body >>", data);

      _return.result = data.resultData;

      if (data.resultCode === 20201) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR postCreateAddress >> ", e);
    }

    return _return;
  };

  const postUpdUserAddress = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateAddress({ data: payload });
      // console.log("data.body >>", data);

      _return.result = data.resultData;

      if (data.resultCode === 20202) {
        _return.status = true;
      }
    } catch (e) {
      console.log("ERR postUpdUserAddress >> ", e);
    }

    return _return;
  };

  const onSubmit = (data) => {
    // console.log("onSubmit >>", data);

    data = {
      ...data,
      name: data.fullname,
    };
    delete data.fullname;

    const payload = {
      ...data,
      userId: stateProfile.dataUser.id,
      mobileNo: convertMobileFormat(data.mobileNo),
      branchCode: data.branchCode !== "" ? data.branchCode : null,
    };
    // console.log("onSubmit >>", payload);

    if (payload.id !== "") {
      postUpdUserAddress(payload).then((_rsData) => {
        // console.log("_rsData >>", _rsData);

        if (_rsData.status === true) {
          setDataAddress(_rsData.result);

          handleOpenModelEditSuccess();
        } else {
          console.warn(_rsData);
        }
      });
    } else {
      postCreateAddress(payload).then((_rsData) => {
        // console.log("_rsData >>", _rsData);

        if (_rsData.status === true) {
          if (data.isDefault === true) {
            setDataAddress(_rsData.result);
          }

          handleOpenModelCreateSuccess();
        } else {
          console.warn(_rsData);
        }
      });
    }
  };
  /* End Handle click submit form */

  /* Modal create success and update success */
  const [isModalCreateSuccessShow, setIsModalCreateSuccessShow] =
    useState(false);
  const [isModalEditSuccessShow, setIsModalEditSuccessShow] = useState(false);

  const handleOpenModelCreateSuccess = () => {
    setIsModalCreateSuccessShow(true);
  };

  const handleCloseModalCreateSuccess = () => {
    setIsModalCreateSuccessShow(false);

    setTimeout(() => navigate("/profile/address/tax-billing"), 200);
  };

  const handleOpenModelEditSuccess = () => {
    setIsModalEditSuccessShow(true);
  };

  const handleCloseModalEditSuccess = () => {
    setIsModalEditSuccessShow(false);

    setTimeout(() => navigate("/profile/address/tax-billing"), 200);
  };
  /* End Modal create success and update success */

  /* First event render */
  const [provinceList, setProvinceList] = useState([]);
  const [isProvinceListLoaded, setIsProvinceListLoaded] = useState(false);

  const getProvince = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await province({ params: payload });
      // console.log("getProvince data >>", data);

      if (data.resultCode === 20200) {
        _return.status = true;
        _return.result = data.resultData;
      }
    } catch (e) {
      console.log("ERR getProvince >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile, stateAddress);

    if (Object.getOwnPropertyNames(stateProfile.dataUser).length === 0) {
      navigate("/");
    } else {
      getProvince({}).then((_rsData) => {
        // console.log(_rsData);

        if (_rsData.status === true) {
          const _length = _rsData.result.length;
          const _province = _rsData.result;
          // console.log(_province, _length);

          for (let i = 0; i < _length; i++) {
            setProvinceList((prevItem) => [...prevItem, _province[i]]);
          }
        }

        setIsProvinceListLoaded(true);
      });

      if (pageView === "create") {
        const _dataUser = stateProfile.dataUser;

        setValue("fullname", `${_dataUser.firstname} ${_dataUser.lastname}`);
        setValue("mobileNo", convertMobileFormat(_dataUser.mobileNo, "-"));
      } else if (pageView === "update") {
        const _dataAddress = stateAddress.data;

        if (Object.keys(_dataAddress).length !== 0) {
          setValue("id", _dataAddress.id);
          setValue("fullname", _dataAddress.name);
          setValue("taxNo", _dataAddress.taxNo);
          setValue(
            "branchName",
            _dataAddress.branchName !== null ? _dataAddress.branchName : ""
          );
          setValue(
            "branchCode",
            _dataAddress.branchCode !== null ? _dataAddress.branchCode : ""
          );
          setValue("addressDetail", _dataAddress.addressDetail);
          setValue("provinceId", _dataAddress.provinceId);
          setValue("amphureId", _dataAddress.amphureId);
          setValue("tombonId", _dataAddress.tombonId);
          setValue("postcode", _dataAddress.postcode);
          setValue("mobileNo", convertMobileFormat(_dataAddress.mobileNo, "-"));
          setValue("isDefault", _dataAddress.isDefault);

          let _chkFormIsValid = true;
          const _chkField = [
            "fullname",
            "taxNo",
            "addressDetail",
            "provinceId",
            "amphureId",
            "tombonId",
            "postcode",
            "mobileNo",
          ];
          Object.entries(_dataAddress).forEach(([key, val]) => {
            if (_chkField.includes(key)) {
              if (val === "" || val === false || val === null) {
                _chkFormIsValid = false;
              }
            }
          });

          if (_chkFormIsValid === true) {
            setFormIsValid(true);
          }
        } else {
          navigate("/profile/address/tax-billing");
        }
      }
    }
  }, []);

  const [provinceMaster, setProvinceMaster] = useState([]);
  const [amphureMaster, setAmphureMaster] = useState([]);
  const [tombonMaster, setTombonMaster] = useState([]);
  const [provinceOption, setProvinceOption] = useState([]);
  const [amphureOption, setAmphureOption] = useState([]);
  const [tombonOption, setTombonOption] = useState([]);
  const [isProvinceOptionSet, setIsProvinceOptionSet] = useState(false);

  useEffect(() => {
    // console.log("provinceList >>", provinceList, provinceList.length);

    if (provinceList.length > 0) {
      for (let i = 0; i < provinceList.length; i++) {
        setProvinceMaster((prevItem) => [
          ...prevItem,
          {
            id: provinceList[i].id,
            nameTh: provinceList[i].nameTh,
            nameEn: provinceList[i].nameEn,
          },
        ]);

        if (provinceList[i].amphure.length > 0) {
          for (let k = 0; k < provinceList[i].amphure.length; k++) {
            setAmphureMaster((prevItem) => [
              ...prevItem,
              {
                id: provinceList[i].amphure[k].id,
                nameTh: provinceList[i].amphure[k].nameTh,
                nameEn: provinceList[i].amphure[k].nameEn,
                provinceId: provinceList[i].amphure[k].provinceId,
              },
            ]);

            if (provinceList[i].amphure[k].tombon.length > 0) {
              for (
                let l = 0;
                l < provinceList[i].amphure[k].tombon.length;
                l++
              ) {
                setTombonMaster((prevItem) => [
                  ...prevItem,
                  {
                    id: provinceList[i].amphure[k].tombon[l].id,
                    nameTh: provinceList[i].amphure[k].tombon[l].nameTh,
                    nameEn: provinceList[i].amphure[k].tombon[l].nameEn,
                    postcode: provinceList[i].amphure[k].tombon[l].postcode,
                    amphureId: provinceList[i].amphure[k].tombon[l].amphureId,
                  },
                ]);
              }
            }
          }
        }
      }
    }
  }, [provinceList]);

  useEffect(() => {
    // console.log(
    //   "master data >>",
    //   provinceMaster,
    //   amphureMaster,
    //   tombonMaster
    // );
    setProvinceOption(provinceMaster);
    setAmphureOption(amphureMaster);
    setTombonOption(tombonMaster);
  }, [provinceMaster, amphureMaster, tombonMaster]);
  /* End First event render */

  /* Handle province and amphure */
  const handleOnProvinceChange = (_value) => {
    // console.log("handleOnProvinceChange >>", _value);

    let _tmpAmphureOption = amphureMaster;
    _tmpAmphureOption = _tmpAmphureOption.filter((item) => {
      if (item.provinceId === parseInt(_value)) {
        return item;
      }
    });

    setAmphureOption(_tmpAmphureOption);
    setValue("amphureId", _tmpAmphureOption[0].id);

    let _tmpTombonOption = tombonMaster;
    _tmpTombonOption = _tmpTombonOption.filter((item) => {
      if (item.amphureId === _tmpAmphureOption[0].id) {
        return item;
      }
    });

    setTombonOption(_tmpTombonOption);
    setValue("tombonId", _tmpTombonOption[0].id);
    setValue("postcode", _tmpTombonOption[0].postcode);

    handleCheckOnChange();
  };

  const handleOnAmphureChange = (_value) => {
    // console.log("handleOnAmphureChange >>", _value);

    let _tmpTombonOption = tombonMaster;
    _tmpTombonOption = _tmpTombonOption.filter((item) => {
      if (item.amphureId === parseInt(_value)) {
        return item;
      }
    });

    setTombonOption(_tmpTombonOption);
    setValue("tombonId", _tmpTombonOption[0].id);
    setValue("postcode", _tmpTombonOption[0].postcode);

    handleCheckOnChange();
  };

  useEffect(() => {
    // console.log("option data >>", provinceOption, amphureOption, tombonOption);
    if (provinceOption.length !== 0) {
      setIsProvinceOptionSet(true);
    }
  }, [provinceOption, amphureOption, tombonOption]);
  /* End Handle province and amphure */

  useEffect(() => {
    if (isProvinceListLoaded === true && isProvinceOptionSet === true) {
      const _dataAddress = stateAddress.data;

      if (Object.keys(_dataAddress).length !== 0) {
        handleOnProvinceChange(_dataAddress.provinceId);
        handleOnAmphureChange(_dataAddress.amphureId);
      }
    }
  }, [isProvinceListLoaded, isProvinceOptionSet]);

  return (
    <>
      <Helmet>
        <title>JIB</title>
        <meta name="title" content="JIB" />
        <meta name="description" content="JIB" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="JIB" />
        <meta property="og:description" content="JIB" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="JIB" />
      </Helmet>

      <div id="pageAddAddressForm">
        <main>
          <PageHeader
            title={
              watch("id") === ""
                ? t.addressFormPage.headerTitle.tax.add
                : t.addressFormPage.headerTitle.tax.edit
            }
            btnBackImgClassName="block"
            btnHref="profile/address/tax-billing"
          />

          <div className="wrapContent">
            <ModalCreateSuccess
              open={isModalCreateSuccessShow}
              onClose={handleCloseModalCreateSuccess}
              addressType={2}
            />
            <ModalEditSuccess
              open={isModalEditSuccessShow}
              onClose={handleCloseModalEditSuccess}
            />

            <section id="pAddAddressForm">
              <div className="bHead">
                <p className="tTitle">{t.addressFormPage.bodyTitle}</p>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => breakKeyDownEnter(e)}
              >
                <div className="bForm">
                  <div
                    className={`control-group validate ${
                      errors.fullname ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.fullname.tax.label}
                    </div>
                    <Controller
                      name="fullname"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.fullname.tax.validate.required,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder={t.form.fullname.tax.placeholder}
                          onInput={Helper.FNFORM.handleCharOnly}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("fullname")}
                          maxLength="100"
                        />
                      )}
                    />
                    {errors.fullname && (
                      <label htmlFor="" className="tError">
                        {errors.fullname.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.taxNo ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">{t.form.taxNo.label}</div>
                    <Controller
                      name="taxNo"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.taxNo.validate.required,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder={t.form.taxNo.placeholder}
                          onInput={Helper.FNFORM.handleNumberOnly}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("taxNo")}
                          maxLength="13"
                        />
                      )}
                    />
                    {errors.taxNo && (
                      <label htmlFor="" className="tError">
                        {errors.taxNo.message}
                      </label>
                    )}
                  </div>
                  <div
                    class={`control-group ${errors.branchName ? "error" : ""}`}
                  >
                    <div class="tTitle">{t.form.branchName.label}</div>
                    <Controller
                      name="branchName"
                      control={control}
                      defaultValue=""
                      // rules={{
                      //   required: t.form.branchName.validate.required,
                      // }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder={t.form.branchName.placeholder}
                          // onInput={Helper.FNFORM.handleCharOnly}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("branchName")}
                          maxLength="100"
                        />
                      )}
                    />
                    {errors.branchName && (
                      <label htmlFor="" className="tError">
                        {errors.branchName.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group ${
                      errors.branchCode ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.branchCode.label}
                    </div>
                    <Controller
                      name="branchCode"
                      control={control}
                      defaultValue=""
                      // rules={{
                      //   required: t.form.branchCode.validate.required,
                      // }}
                      render={({ field: { onChange, ...field } }) => (
                        <input
                          {...field}
                          type="text"
                          placeholder={t.form.branchCode.placeholder}
                          onInput={Helper.FNFORM.handleNumberOnly}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("branchCode")}
                          maxLength="10"
                        />
                      )}
                    />
                    {errors.branchCode && (
                      <label htmlFor="" className="tError">
                        {errors.branchCode.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.addressDetail ? "error" : ""
                    }`}
                  >
                    <div className="tTitle">{t.form.address.label}</div>
                    <Controller
                      name="addressDetail"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.address.validate.required,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <textarea
                          {...field}
                          placeholder={t.form.address.placeholder}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            handleCheckOnChange();
                          }}
                          onFocus={() => setFormIsFocus("addressDetail")}
                          rows="4"
                        ></textarea>
                      )}
                    />
                    {errors.addressDetail && (
                      <label htmlFor="" className="tError">
                        {errors.addressDetail.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.provinceId ? "error" : ""
                    }`}
                  >
                    <div className="tTitle">{t.form.province.label}</div>
                    <Controller
                      name="provinceId"
                      control={control}
                      rules={{
                        required: t.form.province.validate.required,
                      }}
                      defaultValue=""
                      render={({ field: { onChange, ...field } }) => (
                        <div className="select">
                          <select
                            {...field}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              handleOnProvinceChange(value);
                            }}
                            onFocus={() => setFormIsFocus("provinceId")}
                          >
                            <option value="" disabled>
                              {t.form.province.placeholder}
                            </option>
                            {provinceOption.length > 0 &&
                              provinceOption.map(
                                (provinceItem, provinceIndex) => (
                                  <option
                                    value={provinceItem.id}
                                    key={provinceIndex + 1}
                                  >
                                    {lang === "th" ? provinceItem.nameTh : provinceItem.nameEn}
                                  </option>
                                )
                              )}
                          </select>
                        </div>
                      )}
                    />
                    {errors.provinceId && (
                      <label htmlFor="" className="tError">
                        {errors.provinceId.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.amphureId ? "error" : ""
                    }`}
                  >
                    <div className="tTitle">{t.form.amphure.label}</div>
                    <Controller
                      name="amphureId"
                      control={control}
                      rules={{
                        required: t.form.amphure.validate.required,
                      }}
                      defaultValue=""
                      render={({ field: { onChange, ...field } }) => (
                        <div className="select">
                          <select
                            {...field}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              handleOnAmphureChange(value);
                            }}
                            onFocus={() => setFormIsFocus("amphureId")}
                          >
                            <option value="" disabled>
                              {t.form.amphure.placeholder}
                            </option>
                            {amphureOption.length > 0 &&
                              amphureOption.map((amphureItem, amphureIndex) => (
                                <option
                                  value={amphureItem.id}
                                  key={amphureIndex + 1}
                                >
                                  {lang === "th" ? amphureItem.nameTh : amphureItem.nameEn}
                                </option>
                              ))}
                          </select>
                        </div>
                      )}
                    />
                    {errors.amphureId && (
                      <label htmlFor="" className="tError">
                        {errors.amphureId.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.tombonId ? "error" : ""
                    }`}
                  >
                    <div className="tTitle">{t.form.tombon.label}</div>
                    <Controller
                      name="tombonId"
                      control={control}
                      rules={{
                        required: t.form.tombon.validate.required,
                      }}
                      defaultValue=""
                      render={({ field: { onChange, ...field } }) => (
                        <div className="select">
                          <select
                            {...field}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              handleCheckOnChange();
                            }}
                            onFocus={() => setFormIsFocus("tombonId")}
                          >
                            <option value="" disabled>
                              {t.form.tombon.placeholder}
                            </option>
                            {tombonOption.length > 0 &&
                              tombonOption.map((tombonItem, tombonIndex) => (
                                <option
                                  value={tombonItem.id}
                                  key={tombonIndex + 1}
                                >
                                  {lang === "th" ? tombonItem.nameTh : tombonItem.nameEn}
                                </option>
                              ))}
                          </select>
                        </div>
                      )}
                    />
                    {errors.tombonId && (
                      <label htmlFor="" className="tError">
                        {errors.tombonId.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.postcode ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.postcode.label}
                    </div>
                    <Controller
                      name="postcode"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.postcode.validate.required,
                      }}
                      render={({ field: { onChange, ...field } }) => (
                        <div className="inputIcon">
                          <input
                            {...field}
                            type="text"
                            placeholder={t.form.postcode.placeholder}
                            onInput={Helper.FNFORM.handleNumberOnly}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              handleCheckOnChange();
                            }}
                            onFocus={() => setFormIsFocus("postcode")}
                            maxLength="6"
                            disabled={true}
                          />
                        </div>
                      )}
                    />
                    {errors.postcode && (
                      <label htmlFor="" className="tError">
                        {errors.postcode.message}
                      </label>
                    )}
                  </div>
                  <div
                    className={`control-group validate ${
                      errors.mobileNo ? "error" : ""
                    }`}
                  >
                    <div className="tTitle-validate">
                      {t.form.mobileNo.label}
                    </div>
                    <Controller
                      name="mobileNo"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t.form.mobileNo.validate.required,
                        pattern: {
                          value: /^\d{3}-\d{3}-\d{4}$/,
                          message: t.form.mobileNo.validate.pattern,
                        },
                      }}
                      render={({ field: { name, value, onChange } }) => (
                        <div className="inputIcon">
                          <PatternFormat
                            name={name}
                            value={value}
                            displayType="input"
                            format="###-###-####"
                            placeholder={t.form.mobileNo.placeholder}
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              handleCheckOnChange();
                            }}
                            onFocus={() => setFormIsFocus("mobileNo")}
                          />
                        </div>
                      )}
                    />
                    {errors.mobileNo && (
                      <label htmlFor="" className="tError">
                        {errors.mobileNo.message}
                      </label>
                    )}
                  </div>
                </div>
                <div className="bToggle">
                  <div className="bToggle">
                    <div className="btnToggle">
                      <Controller
                        name="isDefault"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <label className=" control--checkbox">
                            <input
                              type="checkbox"
                              {...field}
                              checked={watch("isDefault")}
                              onChange={(e) => {
                                field.onChange(e.target.checked);
                                handleCheckOnChange();
                              }}
                            />
                            <div className="control__toggle">
                              <div className="circle"></div>
                            </div>
                          </label>
                        )}
                      />
                    </div>
                  </div>
                  <p>{t.addressFormPage.setDefaultAddress}</p>
                </div>
                <div className="bBtn">
                  <button
                    className="btn openModalAddAddress width-100 primary"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    {t.addressFormPage.btnSaveAddress}
                  </button>
                </div>
              </form>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default AddressTaxBillingFormPage;
