import React, { useEffect, useState } from "react";

import useTranslations from "../../i18n/useTranslations";

import jBox from "jbox";
import $ from "jquery";

const ModalPrivacyAndNews = ({
  open = false,
  onClose = () => {},
  modalType = "privacy",
}) => {
  const { lang } = useTranslations();

  const [modal, setModal] = useState(null);

  const openModal = () => {
    modal.open();
  };

  const closeModal = () => {
    modal.close();
    onClose();
  };

  const listenerCloseModal = () => {
    const btnClose = document.querySelector("#ModalPdpa .btnCloseModal");
    btnClose.addEventListener("click", () => {
      closeModal();
    });
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalPdpa"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    if (modal && open) {
      openModal();
      listenerCloseModal();
    }
  }, [modal, open]);

  return (
    <>
      <div
        className="bModal bCard"
        id="ModalPdpa"
        style={{
          display: "none",
        }}
      >
        <div className="bInner">
          <div className="closeBtn btnCloseModal"></div>
          {modalType === "privacy" ? (
            <div className="tContent">
              {lang === "th" ? (
                <>
                  <p class="tHead">นโยบายความเป็นส่วนตัว</p>
                  <div class="bContent">
                    <p>บริษัท เจไอบี คอมพิวเตอร์ กรุ๊ป จำกัด</p>
                    <ul>
                      <li>
                        เราให้ความสำคัญต่อความเป็นส่วนตัวของท่าน
                        และจะคุ้มครองข้อมูลส่วนบุคคลของท่านอย่างเคร่งครัด
                        ดังนั้น
                        จึง–เป็นสิ่งสำคัญสำหรับเราที่ทำให้ท่านเข้าใจถึงขั้นตอนและวิธีการของเรา
                        ในการจัดเก็บและประมวลผลข้อมูลส่วนบุคคลของท่าน
                      </li>
                      <li>
                        นโยบายความเป็นส่วนตัวจะใช้บังคับกับการประมวลผลข้อมูลส่วนบุคคลของท่าน
                        ที่จัดเก็บจากการที่ท่านเข้าใช้บริการของเรา
                        ซึ่งรวมถึงการเข้าเยี่ยมชมเว็บไซต์ของเรา ดังนั้น
                        กรุณาอ่านนโยบายความเป็นส่วนตัวฉบับนี้ร่วมกับเงื่อนไขการให้บริการของบริการใดๆ
                        ที่ท่านเข้าใช้
                        ซึ่งอาจกำหนดเงื่อนไขการให้บริการเพิ่มเติมเกี่ยวกับข้อมูลส่วนบุคคลของท่าน
                      </li>
                      <li>
                        นโยบายความเป็นส่วนนี้จะอธิบายถึงลักษณะของข้อมูลส่วนบุคคล
                        ที่เราจัดเก็บรวบรวมเหตุผลและวิธีการขั้นตอนในการจัดเก็บข้อมูล
                        และการประมวลผลข้อมูลเหล่านั้น
                        รวมทั้งวิธีการที่เราแบ่งปันข้อมูลเหล่านั้นต่อบุคคลภายนอก
                        นอกจากนี้
                        นโยบายความเป็นส่วนตัวจะอธิบายไปถึงสิทธิในความเป็นส่วนตัวที่ท่านมีเหนือข้อมูลส่วนบุคคลของท่าน
                        และวิธีการใช้สิทธิดังกล่าว
                      </li>
                    </ul>
                    <p>
                      คุณสามารถอ่านรายละเอียดเพิ่มเติมได้ที่{" "}
                      <a href="https://www.jib.co.th/web/file/pdf/privacy.pdf">
                        คลิ๊กที่นี่
                      </a>
                    </p>
                  </div>
                  <div class="bContent">
                    <p>
                      ติดต่อเจ้าหน้าที่-คุ้มครองข้อมูลส่วนบุคคล อีเมล :
                      dpo@jib.co.th ติดต่อ 02-017-4444 ต่อ 6550, 6171
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <p class="tHead">Privacy Policy</p>
                  <div class="bContent">
                    <p>J.I.B. Computer Group Co., Ltd.</p>
                    <ul>
                      <li>
                        We value your privacy and will strictly protect your
                        personal information. Therefore, it is important for us
                        to ensure that you understand our procedures and methods
                        for collecting and processing your personal data.
                      </li>
                      <li>
                        This privacy policy applies to the processing of your
                        personal data that is collected when you use our
                        services, including visiting our website. Therefore,
                        please read this privacy policy in conjunction with the
                        terms and conditions of any services you use, which may
                        include additional conditions related to your personal
                        data.
                      </li>
                      <li>
                        This privacy policy describes the nature of the personal
                        data we collect, the reasons and methods for collecting
                        and processing that data, and how we share that
                        information with third parties. Additionally, it
                        explains your privacy rights concerning your personal
                        data and how to exercise those rights.
                      </li>
                    </ul>
                    <p>
                      You can read more details at:{" "}
                      <a href="https://www.jib.co.th/web/file/pdf/privacy.pdf">
                        https://www.jib.co.th/web/file/pdf/privacy.pdf
                      </a>
                    </p>
                  </div>
                  <div class="bContent">
                    <p>
                      Contact Information – Data Protection Officer.
                      <br />
                      Email: dpo@jib.co.th
                      <br />
                      Phone: 02-017-4444 ext. 6550, 6171
                    </p>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="tContent">
              {lang === "th" ? (
                <>
                  <p class="tHead">นโยบายความเป็นส่วนตัว</p>
                  <div class="bContent">
                    <p>บริษัท เจไอบี คอมพิวเตอร์ กรุ๊ป จำกัด</p>
                    <p>
                      เราให้ความสำคัญกับความเป็นส่วนตัวของคุณอยู่เสมอ
                      เพื่อรักษาความลับ และควบคุมข้อมูลส่วนบุคคลของคุณให้ปลอดภัย
                    </p>
                  </div>
                  <div class="bContent">
                    <p>เราใคร่ขอความยินยอมจากคุณ ดังนี้ </p>
                    <ul>
                      <li>
                        ความยินยอมในการให้ข้อมูลส่วนบุคคล เพื่อใช้สมัครสมาชิก
                        และรับบริการต่างๆ รวมถึงเอกสาร หรือสัญญาใดๆ
                        ตามความประสงค์ของลูกค้า
                      </li>
                      <li>
                        ความยินยอมให้นำข้อมูลไปใช้พัฒนาให้เกิดการพัฒนาสินค้า
                        หรือบริการให้ดียิ่งขึ้น
                      </li>
                      <li>
                        ความยินยอมให้บริษัทฯ นำเสนอผลิตภัณฑ์ บริการ
                        หรือสิทธิประโยชน์ ต่างๆ
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  <p class="tHead">Privacy Policy (News and Promotions Page)</p>
                  <div class="bContent">
                    <p>J.I.B. Computer Group Co., Ltd.</p>
                    <p>
                      We always prioritize your privacy to maintain
                      confidentiality and control over your personal information
                      to ensure its security.
                    </p>
                  </div>
                  <div class="bContent">
                    <p>We request your consent for the following:</p>
                    <ul>
                      <li>
                        Consent to provide personal data to register as a member
                        and access various services, including documents or
                        contracts according to the customer's request.
                      </li>
                      <li>
                        Consent to use the data for improving and developing
                        products or services.
                      </li>
                      <li>
                        Consent to allow the company to present products,
                        services, or other benefits.
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalPrivacyAndNews;
